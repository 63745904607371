// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon_eKPMj {\n  color: #ff4d4f;\n  cursor: pointer;\n}\n.icon_eKPMj:hover {\n  color: #ffa39e;\n}\n", "",{"version":3,"sources":["webpack://./src/components/RelatedTariff/TariffGridConfig/renders/ErrorRender/ErrorRender.m.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;AACJ;AACI;EACI,cAAA;AACR","sourcesContent":[".icon {\n    color: #ff4d4f;\n    cursor: pointer;\n\n    &:hover {\n        color: #ffa39e;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon_eKPMj"
};
export default ___CSS_LOADER_EXPORT___;
