import { ISecondaryOrder, secondaryOrderStatusCancelable } from '@dltru/dfa-models'
import { Button, DeleteIcon, ShoppingCart } from '@dltru/dfa-ui'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import './style.less'

interface IComponentProps {
    order: ISecondaryOrder
    actionBuy?: (order: ISecondaryOrder) => void
    actionCancel: (order: ISecondaryOrder) => void
}

export const SecondaryOrderActionButton: FC<IComponentProps> = ({
    order,
    actionBuy,
    actionCancel,
}) => {
    const currentUserUuid = useSelector(authSelector.selectUserUid)
    const isQualify = useSelector(profileSelector.selectIsQualify)
    const onClickBuy = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        actionBuy?.(order)
    }
    const onClickCancel = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        actionCancel(order)
    }
    const showCancelBtn =
        order.user_id === currentUserUuid && secondaryOrderStatusCancelable.includes(order.status)

    return (
        <div className="visible-only-row-hover">
            {actionBuy && order.user_id !== currentUserUuid && isQualify && (
                <Button
                    onClick={onClickBuy}
                    className="grid-action-button"
                    size="small"
                    borderRadius={8}
                >
                    <ShoppingCart />
                </Button>
            )}
            {showCancelBtn && (
                <Button
                    onClick={onClickCancel}
                    className="grid-action-button"
                    size="small"
                    borderRadius={8}
                >
                    <DeleteIcon />
                </Button>
            )}
        </div>
    )
}
