import { ITariffGrid, ITariffPlane } from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, getTariffCode } from '@dltru/dfa-ui'

export const gridConfig = (data: ITariffPlane) =>
    [
        {
            title: 'Дата назначения',
            dataIndex: 'archived_at',
            width: '180px',
            render: () => DateTimeCell(data.active_grid?.activated_at),
        },

        {
            title: 'Код ТП',
            dataIndex: 'name',
            width: '110px',
            render: () => getTariffCode(data),
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: '180px',
            render: () => data.name,
        },
    ] as ColumnsType<ITariffGrid>
