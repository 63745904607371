// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".expandContainer_DH5rB {\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  background: white;\n}\n.baseTitle__8llt {\n  margin-top: 16px;\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/RelatedTariff/RelatedTariff.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,WAAA;AAAJ","sourcesContent":[".expandContainer {\n    padding: 24px;\n    display: flex;\n    flex-direction: column;\n    background: white;\n}\n\n.baseTitle {\n    margin-top: 16px;\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandContainer": "expandContainer_DH5rB",
	"baseTitle": "baseTitle__8llt"
};
export default ___CSS_LOADER_EXPORT___;
