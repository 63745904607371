import { EditableTariffColumnType, GridConfigProps } from './types'
import {
    renderEditableNumber,
    renderError,
    renderName,
    renderPercentSwitcher,
    renderPercentSwitcherMax,
    renderPercentSwitcherMin,
} from './renders/renders'

export const getTariffEditGridConfig = ({
    feeNames,
    isEditable,
}: GridConfigProps): EditableTariffColumnType[] => [
    {
        title: 'Код комиссии',
        dataIndex: 'fee_code',
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'name',
        render: renderName(feeNames),
    },
    {
        title: 'Минимальная комиссия',
        dataIndex: 'minimum_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'minimum_show_type',
        render: renderPercentSwitcherMin('minimum_show_type'),
    },
    {
        title: 'Расчётная комиссия',
        dataIndex: 'amount_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'amount_show_type',
        render: renderPercentSwitcher('amount_show_type'),
    },
    {
        title: 'Максимальная комиссия',
        dataIndex: 'maximum_show',
        editable: isEditable,
        render: renderEditableNumber(isEditable),
    },
    {
        title: '',
        width: '80px',
        dataIndex: 'maximum_show_type',
        render: renderPercentSwitcherMax('maximum_show_type'),
    },

    {
        title: '',
        width: '80px',
        dataIndex: 'error',
        render: renderError,
    },
]
