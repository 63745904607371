import { ETariffTypeTitle, stringToNumber } from '@dltru/dfa-models'
import { Tooltip, ExclamationCircle } from '@dltru/dfa-ui'
import IAppState from '@store/states'
import { tariffSlice } from '@store/tariff'
import { tariffSelector } from '@store/tariff/selectors'
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorType1, ErrorType2, ErrorType3 } from './consts'

import styles from './ErrorRender.m.less'

export interface IErrorRenderProps {
    feeCode: string
}

export const ErrorRender: FC<IErrorRenderProps> = ({ feeCode }) => {
    const dispatch = useDispatch()
    const tariffByFeeCode = useSelector((state: IAppState) =>
        tariffSelector.selectTempTariffByFeeCode(state, feeCode),
    )

    const [error, setError] = useState('')

    const errorType1 = ErrorType1(feeCode)
    const errorType2 = ErrorType2(feeCode)

    useEffect(() => {
        if (tariffByFeeCode) {
            const amount = stringToNumber(tariffByFeeCode.amount_show)
            const maximum = stringToNumber(tariffByFeeCode.maximum_show)
            const minimum = stringToNumber(tariffByFeeCode.minimum_show)

            if (
                tariffByFeeCode.maximum_show_type === tariffByFeeCode.minimum_show_type &&
                tariffByFeeCode.maximum_show &&
                tariffByFeeCode.minimum_show &&
                minimum > maximum
            ) {
                setError(ErrorType3)
                return
            }

            if (amount && tariffByFeeCode.amount_show_type === ETariffTypeTitle.Percent) {
                let errorMessage = errorType1

                if (maximum || minimum) {
                    errorMessage = ''
                }

                setError(errorMessage)
                return
            }

            if (!amount && maximum && minimum) {
                setError(errorType2)
                return
            }

            setError('')
        }
    }, [tariffByFeeCode])

    useEffect(() => {
        dispatch(
            tariffSlice.actions.updateTempTariff({
                feeCode,
                update: { error: Boolean(error) },
            }),
        )
    }, [error, feeCode])

    if (!error) {
        return null
    }

    return (
        <Tooltip title={error}>
            <span className={styles.icon}>
                <ExclamationCircle />
            </span>
        </Tooltip>
    )
}
