import { IRateBaseFront } from '@dltru/dfa-models'
import { DropdownSelectorCell, TDropdowItem } from '@dltru/dfa-ui'
import { tariffSlice } from '@store/tariff'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'

interface ITypeSwitchProps {
    value: string
    record: IRateBaseFront
    name: string
    items: TDropdowItem[]
}

export const TypeSwitch: FC<ITypeSwitchProps> = ({ value, record, name, items }) => {
    const dispatch = useDispatch()

    const onclickHandler = useCallback(
        (item: TDropdowItem) => {
            const valueName = name.replace('_type', '')

            dispatch(
                tariffSlice.actions.updateTempTariff({
                    feeCode: record?.fee_code,
                    update: { [name]: item.value, [valueName]: 0 },
                }),
            )
        },
        [name, record?.fee_code],
    )

    return (
        <DropdownSelectorCell
            items={items}
            defaultItemKey={value ?? items[0].key}
            isChangingLabel
            onItemClick={onclickHandler}
        />
    )
}
