
import { TariffColumnType } from './types'
import { renderPureName, renderValueWithType } from './renders/renders'
import { IRateBaseFront } from '@dltru/dfa-models'


export const getTariffViewGridConfig = (): TariffColumnType[] => [
    {
        title: 'Код комиссии',
        dataIndex: 'fee_code',
    },
    {
        title: 'Наименование комиссии',
        dataIndex: 'name',
        render: renderPureName,
    },
    {
        title: 'Минимальная комиссия',
        dataIndex: 'minimum_show',
        render: (value: string, record: IRateBaseFront) => renderValueWithType(record.minimum_show_type, value),
    },

    {
        title: 'Расчётная комиссия',
        dataIndex: 'amount_show',
        render: (value: string, record: IRateBaseFront) => renderValueWithType(record.amount_show_type, value),
    },

    {
        title: 'Максимальная комиссия',
        dataIndex: 'maximum_show',
        render: (value: string, record: IRateBaseFront) => renderValueWithType(record.maximum_show_type, value),
    },

]
