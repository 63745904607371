import { ITariffGrid } from '@dltru/dfa-models'
import { Box, OpeningPanel, Space, Table, TariffBaseInfo, getTariffCode } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { tariffSelector } from '@store/tariff/selectors'
import { tariffSlice } from '@store/tariff'
import { TariffsViewTable } from './TariffsViewTable'
import { mapToDatasource } from './utils'
import { gridConfig } from './gridConfig'

import styles from './RelatedTariff.m.less'

interface IRelatedTariffProps {
    userUuid?: string
    dfaId?: string | number
}

export const RelatedTariff: FC<IRelatedTariffProps> = ({ userUuid, dfaId }) => {
    const reduxDispatch = useDispatch()
    const data = useSelector(tariffSelector.selectCurrentTariff)
    const baseTariff = useSelector(tariffSelector.selectBaseTariff)
    const feeNames = useSelector(tariffSelector.selectFeeNames)
    const fees = useSelector(tariffSelector.selectFees)

    const expandable = useMemo(() => {
        return {
            expandedRowRender: (record: ITariffGrid) => {
                const dataSource = mapToDatasource(record.rates, fees)
                return (
                    <div className={styles.expandContainer}>
                        <TariffsViewTable dataSource={dataSource} />
                        {data?.type !== 'BASE' && (
                            <div className={styles.baseTitle}>
                                <TariffBaseInfo data={baseTariff ?? undefined} fees={fees} />
                            </div>
                        )}
                    </div>
                )
            },
            rowExpandable: (record: ITariffGrid) => record.rates?.length,
        }
    }, [feeNames, baseTariff, fees, data])

    useEffect(() => {
        if (userUuid) {
            reduxDispatch(tariffSlice.actions.getCurrentTariffByUserUuid(userUuid))
        }

        if (dfaId) {
            reduxDispatch(tariffSlice.actions.getCurrentTariffByDfaId(dfaId))
        }

        reduxDispatch(tariffSlice.actions.getFees())
        reduxDispatch(tariffSlice.actions.getBaseTariff())
        return () => {
            reduxDispatch(tariffSlice.actions.clearItem())
        }
    }, [userUuid, dfaId])

    if (!data) {
        return null
    }

    return (
        <OpeningPanel
            title={`Тарифный план ${getTariffCode(data)} ${data.name} назначен с ${dayjs(
                data.active_grid?.activated_at,
            ).format('DD.MM.YYYY')}`}
        >
            <Box padding={[0, 24]} className="tablebox">
                <Space direction="vertical">
                    <Table
                        className="relatedTariffTable"
                        columns={gridConfig(data)}
                        dataSource={data?.active_grid ? [data?.active_grid] : []}
                        expandable={expandable}
                        rowKey="id"
                    />
                </Space>
            </Box>
        </OpeningPanel>
    )
}
