import { ETariffTypeTitle } from './types'

export const ItemsLabels = {
    [ETariffTypeTitle.Percent]: 'Процент от суммы операции',
    [ETariffTypeTitle.PercentPerYear]: 'Процентная ставка, % в год',
    [ETariffTypeTitle.Rubles]: 'Фиксированная сумма в рублях',
}

export const ItemsTitles = {
    [ETariffTypeTitle.Percent]: '%',
    [ETariffTypeTitle.PercentPerYear]: '% в год',
    [ETariffTypeTitle.Rubles]: 'рублей',
}

export const itemsPercent = [
    {
        key: ETariffTypeTitle.Percent,
        label: ItemsLabels[ETariffTypeTitle.Percent],
        title: ItemsTitles[ETariffTypeTitle.Percent],
        value: ETariffTypeTitle.Percent,
    },

    {
        key: ETariffTypeTitle.PercentPerYear,
        label: ItemsLabels[ETariffTypeTitle.PercentPerYear],
        title: ItemsTitles[ETariffTypeTitle.PercentPerYear],
        value: ETariffTypeTitle.PercentPerYear,
    },
]

export const itemsPercentOnly = [
    {
        key: ETariffTypeTitle.Percent,
        label: ItemsLabels[ETariffTypeTitle.Percent],
        title: ItemsTitles[ETariffTypeTitle.Percent],
        value: ETariffTypeTitle.Percent,
    },
]

export const itemsFix = [
    {
        key: ETariffTypeTitle.Percent,
        label: ItemsLabels[ETariffTypeTitle.Percent],
        title: ItemsTitles[ETariffTypeTitle.Percent],
        value: ETariffTypeTitle.Percent,
    },

    {
        key: ETariffTypeTitle.Rubles,
        label: ItemsLabels[ETariffTypeTitle.Rubles],
        title: ItemsTitles[ETariffTypeTitle.Rubles],
        value: ETariffTypeTitle.Rubles,
    },
]

// комиссии только с расчетной комиссию в простых % от суммы и исключить для них возможность выбора процентной ставки (% год)
// https://masterchain.atlassian.net/browse/DFA-7397?focusedCommentId=43930
export const pureComissionsCode = ['CM-OTH-017', 'CM-OTH-026', 'CM-OTH-031']
