// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grayText_V5iUJ {\n  color: #b9bac1;\n}\n.cell_HyTU0 [class*='ant-form-item-control-input'] {\n  padding-bottom: 0px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/RelatedTariff/TariffGridConfig/renders/style.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;AAIA;EACI,8BAAA;AAFJ","sourcesContent":["@import '/src/assets/style/palette';\n\n.grayText {\n    color: @gray-6;\n}\n\n.cell [class*='ant-form-item-control-input'] {\n    padding-bottom: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grayText": "grayText_V5iUJ",
	"cell": "cell_HyTU0"
};
export default ___CSS_LOADER_EXPORT___;
