import { ETariffTypeTitle, IRateBaseFront, stringToNumber } from '@dltru/dfa-models'
import { getRublesFromPennies, FormItem } from '@dltru/dfa-ui'
import {
    itemsFix,
    itemsPercent,
    itemsPercentOnly,
    ItemsTitles,
    pureComissionsCode,
} from '../consts'
import { ErrorRender } from './ErrorRender/ErrorRender'
import styles from './style.m.less'
import { TypeSwitch } from './TypeSwitch/TypeSwitch'

export const renderPureName = (value: string) => value
export const renderName =
    (feeNames: Record<string, string> | undefined) => (value: string, record: IRateBaseFront) =>
        value || feeNames?.[record.fee_code]

export const renderEditableNumber = (isEditable?: boolean) => (value?: string) =>
    value ? (
        (isEditable ? value : stringToNumber(value)).toLocaleString()
    ) : (
        <span className={styles.grayText}>0</span>
    )

export const renderNumber = (value?: string) =>
    value ? stringToNumber(value).toLocaleString() : <span className={styles.grayText}>0</span>

export const renderPenniesNumber = (value?: number) =>
    value ? getRublesFromPennies(value) : <span className={styles.grayText}>0</span>

export const renderPercentSwitcherMin =
    (name: string) => (value: string, record: IRateBaseFront) => {
        const fieldName = `${record?.fee_code}${name}`

        return (
            <FormItem name={fieldName} className={styles.cell}>
                <TypeSwitch items={itemsFix} value={value} record={record} name={name} />
            </FormItem>
        )
    }

export const renderPercentSwitcherMax =
    (name: string) => (value: string, record: IRateBaseFront) => {
        const fieldName = `${record?.fee_code}${name}`

        return (
            <FormItem name={fieldName} className={styles.cell}>
                <TypeSwitch items={itemsFix} value={value} record={record} name={name} />
            </FormItem>
        )
    }

export const renderPercentSwitcher = (name: string) => (value: string, record: IRateBaseFront) => {
    const fieldName = `${record?.fee_code}${name}`

    const items = pureComissionsCode.includes(record?.fee_code?.trim())
        ? itemsPercentOnly
        : itemsPercent

    return (
        <FormItem name={fieldName} className={styles.cell}>
            <TypeSwitch items={items} value={value} record={record} name={name} />
        </FormItem>
    )
}

export const renderError = (value: boolean, record: IRateBaseFront) => {
    const fieldName = `${record?.fee_code}error`

    return (
        <FormItem name={fieldName} className={styles.cell}>
            <ErrorRender feeCode={record.fee_code} />
        </FormItem>
    )
}

export const renderValueWithType = (type?: ETariffTypeTitle, value?: string) => {
    if (!type || !value) {
        return <span className={styles.grayText}>0</span>
    }

    const renderedType = ItemsTitles[type]

    return (
        <>
            <span>{value}</span> <span className={styles.grayText}>{renderedType}</span>
        </>
    )
}
