import { Button, RefillModal, getRublesFromPennies } from '@dltru/dfa-ui'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import style from './style.m.less'

export const MyBalanceWidget = () => {
    const account = useSelector(moneySelector.selectAccount)
    const profileWallet = useSelector(profileSelector.selectWalletDfaData)
    const [isModal, setIsModal] = useState(false)

    return (
        <div className={style.MyBalanceWidget}>
            <div className={style.MyBalanceWidgetHeader}>Мой баланс</div>
            <div className={style.MyBalanceWidgetTotal}>
                {getRublesFromPennies(account?.balance.available)}
                <span className={style.MyBalanceWidgetSymbol}>₽</span>
            </div>
            <div className={style.MyBalanceWidgetLabel}>Зарезервировано</div>
            <div className={style.MyBalanceWidgetBalance}>
                {getRublesFromPennies(account?.balance.reserved)}
                <span className={style.MyBalanceWidgetSymbol}>₽</span>
                {/*<span className={style.MyBalanceWidgetTooltip}>
                    <QuestionCircle />
                </span>*/}
            </div>
            <div className={style.MyBalanceWidgetLabel}>Всего</div>
            <div className={style.MyBalanceWidgetBalance}>
                {getRublesFromPennies(account?.balance.total)}
                <span className={style.MyBalanceWidgetSymbol}>₽</span>
                {/*<span className={style.MyBalanceWidgetTooltip}>
                    <QuestionCircle />
                </span>*/}
            </div>
            <Button
                onClick={() => setIsModal(true)}
                style={{ marginTop: 8, width: 'fit-content' }}
                borderRadius={8}
                disabled={!profileWallet}
                tooltipOnDisable="Операция временно недоступна. Повторите попытку позже"
            >
                Пополнить
            </Button>
            <RefillModal isModal={isModal} setIsModal={setIsModal} account={account ?? undefined} />
        </div>
    )
}
