import { IRateBaseFront } from '@dltru/dfa-models'
import { TDropdowItem, ColumnType } from '@dltru/dfa-ui'

export type TSwitcherCallback = (item: TDropdowItem, name: string, record: IRateBaseFront) => void

export type GridConfigProps = {
    feeNames?: Record<string, string>
    isEditable?: boolean
}

export type EditableTariffColumnType = ColumnType<IRateBaseFront> & { editable?: boolean }

export type TariffColumnType = ColumnType<IRateBaseFront>

export enum ETariffTypeTitle {
    Percent = 'Percent',
    PercentPerYear = 'PercentPerYear',
    Rubles = 'Rubles',
}
