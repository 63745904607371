import { FC, useMemo } from 'react'
import { IRateBaseFront } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { getTariffViewGridConfig } from './TariffGridConfig'

export interface ITariffsViewTableProps {
    dataSource: IRateBaseFront[]
}

export const TariffsViewTable: FC<ITariffsViewTableProps> = ({ dataSource }) => {
    const columns = useMemo(() => getTariffViewGridConfig(), [])

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="fee_code"
            uneditable={true}
            noPagination
        />
    )
}
